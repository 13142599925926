import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tooltip as MUITooltip } from '@mui/material';

import { IconButtonWithContainer } from 'src/components_payroll/Buttons';
import { IconCancel, IconQuestion } from 'src/components_payroll/Icons';

import { urlWithTours } from '../constants';

export function TourControlButton({ run, setRun, setStepIndex }) {
  const location = useLocation();

  const path = useMemo(() => location.pathname, [location.pathname]);

  const runTour = () => {
    setStepIndex(0);
    setRun(true);
  };

  const stopTour = () => {
    setStepIndex(0);
    setRun(false);
  };

  return (
    urlWithTours.some(url => path.includes(url)) && (
      <Box position="absolute" top="50px" right="50px" width="4rem" height="4rem">
        <MUITooltip title={run ? 'Cancel onboard' : 'Run onboard'}>
          {run ? (
            <IconButtonWithContainer
              Icon={<IconCancel color="light.primary.shades.60p" height="1.5rem" width="1.5rem" />}
              onClick={stopTour}
            />
          ) : (
            <IconButtonWithContainer
              Icon={<IconQuestion color="light.primary.shades.60p" height="1.5rem" width="1.5rem" />}
              onClick={runTour}
            />
          )}
        </MUITooltip>
      </Box>
    )
  );
}

export default TourControlButton;
