import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Stack } from '@mui/material';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { TextLink } from 'src/components_payroll/Texts';

import * as authSelectors from 'src/store/auth/selectors';

const settingsRoleLink = '/member-admin/settings/role';
const settingsProjectLink = '/member-admin/settings/projects';

export const PositionDetailsTooltip = () => {
  const user = useSelector(authSelectors.getUser);

  const isUserAdmin = useMemo(() => user?.role === 'hunter-admin', [user]);

  return (
    <Stack gap="1rem">
      <HelpBlock title="Add Role">
        A role can be created in the company settings under the &quot;Additional role&quot;.
        {isUserAdmin && (
          <>
            Click <TextLink title="here" navigateTo={settingsRoleLink} /> to create a new role
          </>
        )}
      </HelpBlock>
      {isUserAdmin && (
        <HelpBlock title="Add Project">
          If a project is missing, you can add it <TextLink title="(link)" navigateTo={settingsProjectLink} />.
        </HelpBlock>
      )}
    </Stack>
  );
};
