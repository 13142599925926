import { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { defaultValue, OnBoardContext } from './context';
import onBoardSteps from './steps';
import Tooltip from './components/Tooltip';
import TourControlButton from './components/ContolButton';

export function OnBoard({ children }) {
  const locate = useLocation();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [startedTourName, setStartedTourName] = useState(null);
  const [helpers, setHelpers] = useState(defaultValue.helpers);

  const contextValue = useMemo(() => ({ run: tourName => setStartedTourName(tourName), helpers }), [helpers]);

  useEffect(() => {
    if (startedTourName) {
      setSteps(onBoardSteps[startedTourName]);
      setStepIndex(0);
      setRun(true);
    }
  }, [startedTourName]);

  useEffect(() => {
    if (steps[stepIndex]?.navigateTo && steps[stepIndex]?.navigateTo === locate.pathname) helpers.next();
  }, [locate.pathname]);

  const handleJoyrideCallback = data => {
    const { action, index, origin, status, type, _step } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(action, index, origin, status, type); // eslint-disable-line no-console
    console.groupEnd();
  };

  return (
    <OnBoardContext.Provider value={contextValue}>
      {run && (
        <Joyride
          callback={handleJoyrideCallback}
          run={run}
          stepIndex={stepIndex}
          steps={steps}
          continuous
          spotlightClicks
          getHelpers={setHelpers}
          showProgress
          styles={{ options: { zIndex: 1250 } }}
          disableOverlayClose
          tooltipComponent={Tooltip}
        />
      )}

      <TourControlButton run={run} setRun={setRun} setStepIndex={setStepIndex} />

      <Box height="100%">{children}</Box>
    </OnBoardContext.Provider>
  );
}
