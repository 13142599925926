import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

const HelpBlock = ({ title, children }) => (
  <Stack gap="0.25rem">
    <Typography variant="H3">{title}</Typography>
    <Typography variant="SUBTITLE1">{children}</Typography>
  </Stack>
);

HelpBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HelpBlock;
