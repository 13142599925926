export const onBoardNames = {
  OFFER: {
    CHOOSE_TYPE: 'chooseType',
    STEPS: {
      MEMBERS_DATA: 'membersData',
      POSITION: 'position',
      PAYMENT: 'payment',
      POLiCY: 'policy',
      REVIEW: 'review',
    },
  },
};

export const urlWithTours = ['team/add/offer', 'team/add/client'];
