import { onBoardNames } from '../constants';

import choseOfferType from './choseOfferType';
import offerStep1 from './offerStep1';
import offerStep2 from './offerStep2';
import offerStep3 from './offerStep3';
import offerStep4 from './offerStep4';
import offerStep5 from './offerStep5';

export default {
  [onBoardNames.OFFER.CHOOSE_TYPE]: choseOfferType,
  [onBoardNames.OFFER.STEPS.MEMBERS_DATA]: offerStep1,
  [onBoardNames.OFFER.STEPS.POSITION]: offerStep2,
  [onBoardNames.OFFER.STEPS.PAYMENT]: offerStep3,
  [onBoardNames.OFFER.STEPS.POLiCY]: offerStep4,
  [onBoardNames.OFFER.STEPS.REVIEW]: offerStep5,
};
